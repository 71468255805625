import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "description": "Test MDX Test",
  "modifiedDate": "2019-11-11",
  "partOfBook": true,
  "previewImage": "node-showit.png",
  "publishedDate": "2019-10-14",
  "slug": "installing-nodejs-ubuntu",
  "title": "Test MDX Test"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hello MDX`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      